import { dew as _commandsDew } from "./commands";
import { dew as _AGGREGATEDew } from "./commands/AGGREGATE";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.AggregateGroupByReducers = exports.AggregateSteps = exports.VectorAlgorithms = exports.SchemaTextFieldPhonetics = exports.SchemaFieldTypes = exports.default = void 0;

  var commands_1 = _commandsDew();

  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function () {
      return commands_1.default;
    }
  });

  var commands_2 = _commandsDew();

  Object.defineProperty(exports, "SchemaFieldTypes", {
    enumerable: true,
    get: function () {
      return commands_2.SchemaFieldTypes;
    }
  });
  Object.defineProperty(exports, "SchemaTextFieldPhonetics", {
    enumerable: true,
    get: function () {
      return commands_2.SchemaTextFieldPhonetics;
    }
  });
  Object.defineProperty(exports, "VectorAlgorithms", {
    enumerable: true,
    get: function () {
      return commands_2.VectorAlgorithms;
    }
  });

  var AGGREGATE_1 = _AGGREGATEDew();

  Object.defineProperty(exports, "AggregateSteps", {
    enumerable: true,
    get: function () {
      return AGGREGATE_1.AggregateSteps;
    }
  });
  Object.defineProperty(exports, "AggregateGroupByReducers", {
    enumerable: true,
    get: function () {
      return AGGREGATE_1.AggregateGroupByReducers;
    }
  });
  return exports;
}