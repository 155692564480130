import { dew as _Dew } from ".";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformArguments = exports.IS_READ_ONLY = void 0;

  const _1 = _Dew();

  exports.IS_READ_ONLY = true;

  function transformArguments(index, query, options) {
    const args = ["FT.EXPLAIN", index, query];
    (0, _1.pushParamsArgs)(args, options?.PARAMS);

    if (options?.DIALECT) {
      args.push("DIALECT", options.DIALECT.toString());
    }

    return args;
  }

  exports.transformArguments = transformArguments;
  return exports;
}