import * as _genericTransformers2 from "@redis/client/dist/lib/commands/generic-transformers";

var _genericTransformers = "default" in _genericTransformers2 ? _genericTransformers2.default : _genericTransformers2;

import { dew as _Dew } from ".";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.IS_READ_ONLY = exports.FIRST_KEY_INDEX = void 0;
  const generic_transformers_1 = _genericTransformers;

  const _1 = _Dew();

  exports.FIRST_KEY_INDEX = 1;
  exports.IS_READ_ONLY = true;

  function transformArguments(index, query, options) {
    return (0, _1.pushSearchOptions)(["FT.SEARCH", index, query], options);
  }

  exports.transformArguments = transformArguments;

  function transformReply(reply) {
    const documents = [];

    for (let i = 1; i < reply.length; i += 2) {
      const tuples = reply[i + 1];
      documents.push({
        id: reply[i],
        value: tuples.length === 2 && tuples[0] === "$" ? JSON.parse(tuples[1]) : (0, generic_transformers_1.transformTuplesReply)(tuples)
      });
    }

    return {
      total: reply[0],
      documents
    };
  }

  exports.transformReply = transformReply;
  return exports;
}